<template>
    <layout>
        <PageHeader title="Add-on Services" />
        <div class="card">
            <div class="main-from-group card-body">
                <form enctype="multipart/form-data">
                    <div class="row">
                        <!-- <div class="col-lg-5 col-xl-4"> -->
                        <div class="col-12 col-md-6 mb-md-0 mb-3">
                            <label class="form-label">Select Story *</label>
                            <div class="single-select2-cstm">
                                <Select2 v-model="addOn.story_id" placeholder="Select Story" :options="stories"
                                    :settings="{ settingOption: value, settingOption: value }"
                                    @select="mySelectEvent($event)"
                                    :class="{ 'is-invalid': submitted && v$.addOn.story_id.$error, }" />
                                <div v-if="submitted && v$.addOn.story_id.$error" class="invalid-feedback">
                                    <span v-if="v$.addOn.story_id.required.$message">{{
                                        v$.addOn.story_id.required.$message
                                        }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <label for="word-count-input">Approx. Word Count <sup class="text-theme">(round down to
                                    nearest 25)</sup>
                                *</label>
                            <input id="word-count-input"  type="number" class="form-control" placeholder="Word Count"
                                v-model="addOn.wordCount"
                                :class="{ 'is-invalid': submitted && v$.addOn.wordCount.$error, }" />
                            <div v-if="submitted && v$.addOn.wordCount.$error" class="invalid-feedback">
                                <span v-if="v$.addOn.wordCount.required.$message">{{
                                    v$.addOn.wordCount.required.$message }}</span>
                            </div>
                        </div>
                        <!-- </div> -->
                    </div>
                    <div class="innerGroupFeilds p-2 p-md-3 mb-4 mt-3">
                        <!-- <h2 class="main-title-head">Add-on Services</h2> -->
                        <ServiceSub :isSignUp="true" @addOnPackage="setAddOnservice"  ref="serviceSubComponent" />
                        
                        <div v-if="submitted && v$.addOn.addOnPackage.$error" class="text-error">
                            <span style="color:red;" v-if="v$.addOn.addOnPackage.required.$message">{{
                                v$.addOn.addOnPackage.required.$message }}</span>
                        </div>
                    </div>
                    <div>
                        <h5 class="font-size-15 mb-4 text-center">Please update your word count above. If applicable, send a new version of your work to <a class="text-theme" href="mailto:info@submititnow.com">info@submititnow.com.</a> (Upload option: coming soon.)</h5>
                    </div>
                    <div class="text-end mt-2">
                        <button type="button" @click="addOnService" class="btn btn-theme">
                            Submitit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </layout>
</template>
<script>
    import Layout from "../../router/layouts/main";
    import PageHeader from "@/components/admin/header/page-header";
    import Select2 from 'vue3-select2-component';
    import ServiceSub from "../../router/views/sub-components/service";
    import axios from "axios";
    import CryptoJS from "crypto-js";
    import useVuelidate from "@vuelidate/core";
    import { required, helpers } from "@vuelidate/validators";

    export default {
        page: {
            title: "Add on Service",
            meta: [
                {
                    name: "description",
                    content: "description",
                },
            ],
        },

        data() {
            return {
                title: true,
                url: process.env.VUE_APP_URL,
                v$: useVuelidate(),
                submitted: false,
                fetchData:false,
                addOn: {
                    story_id: '',
                    wordCount: '',
                    addOnPackage: '',
                },
                tableItems: [],
                stories: [],
                submission:'',
                serviceProps:'',
                flash:'',
                amount:0
            };
        },
        validations: {
            addOn: {
                story_id: {
                    required: helpers.withMessage("Story  is required", required),
                },
                wordCount: {
                    required: helpers.withMessage("Word Count is required", required),
                },
                addOnPackage:{
                    required: helpers.withMessage("Select a Package",required)
                }
            }
        },
        props: ["email"],
        components: {
            Layout,
            PageHeader,
            ServiceSub,
            Select2
        },
        watch: {
            $route() {
                this.resetFields();
            },
        },
        activated() {
            if(this.fetchData){
                this.getAllStories();
            }
        },
        beforeMount() {
            if(!this.fetchData){
                this.getAllStories();
            }

        },
        methods: {
            getAllStories() {
                axios.post(this.url + 'api/clientStories', { id: atob(decodeURIComponent(JSON.parse(localStorage.getItem('authenticatedUserId')))) }).then(res => {
                    this.tableItems = res.data
                    this.stories = res.data.map(({ id, storyTitle }) => ({
                        id: id,
                        text: storyTitle,
                    }));
                    this.fetchData = true;
                    // this.storyTitleOptions = this.tableData.map(({ storyTitle }) => storyTitle);
                })
            },
            mySelectEvent(event) {
                this.$refs.serviceSubComponent.uncheckAddOn();
                this.addOn.addOnPackage = '';
                this.$refs.serviceSubComponent.serviceDataII(event.id);
                // this.$refs.serviceSubComponent.getAllStories(event.id);
                this.serviceProps = event;
                let word = this.tableItems.find(item => item.id == event.id);
                this.addOn.story_id = event.id;
                this.addOn.wordCount = word.wordCount;
                this.submission = this.tableItems.filter(item => item.id == event.id).map(item => item.submissionPackageId);
                this.flash = this.tableItems.filter(item => item.id == event.id).map(item => item.fictionPackageId);
            },
            addOnService() {
                this.submitted = true;
                this.v$.$touch();
                if (this.v$.$invalid) {
                    return;
                } else {
                    this.$router.push({
                        path: "/add-on-payment", query: {
                            story_id: this.addOn.story_id,
                            wordCount: this.addOn.wordCount,
                            addOnPackage: this.addOn.addOnPackage,
                            submission: this.submission? this.submission:'',
                            flash:this.flash ? this.flash : '',
                            amount:this.amount
                        }
                    });
                    // axios.post(this.url + "api/addOnservice", this.addOn).then(() => {
                    //     this.$store.state.isAccepted = true
                    //     this.$store.state.notificationData = 'Story Added Successfully'
                    //     this.$router.push({ path: "/stories-listing" });
                    //     setTimeout(() => {
                    //         this.$store.state.isAccepted = false
                    //         this.$store.state.notificationData = null
                    //     }, 2500)
                    // });
                }
            },

            setAddOnservice(id) {
                if(id){
                    this.addOn.addOnPackage = id[0];
                    this.amount = id[1];
                }
            },



            resetFields() {
                this.$refs.serviceSubComponent.uncheckAddOn();
                this.submitted = false;
                this.addOn = {
                    story_id: '',
                    wordCount: '',
                    addOnPackage: '',
                };
            },

            decode(id) {
                return decodeURIComponent(
                    CryptoJS.AES.decrypt(String(id), "Secret Passphrase").toString(
                        CryptoJS.enc.Utf8
                    )
                );
            },
        },
    };
</script>