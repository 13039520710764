<template>
    <div class="row gx-3">
        <div class="row gx-3">
            <div class="col mt-4" v-if="$route.name !== 'add-on-service'">
                <div class="contentServiceCstmtitle">
                    <h5> For stories over 3,500 words, an additional charge of $35/1,000 words (over 3,500) will be
                        added (waived with editing services). <span class="text-theme">All services: 100% money-back
                            guaranteed (within 30 days).</span>
                        <!-- For more details, see our <a
                        href="https://www.submititnow.com/submission-packages" class="text-theme"
                        target="_blank">Services page.</a> -->
                    </h5>
                </div>
            </div>

        </div>
        <div class="col-lg-4 mb-3 mb-lg-0"
            v-if="isSignUp && !($route.name === 'add-on-service' || $route.name === 'single-story')">
            <div class="package-card-main light-blue-pack">
                <h3 class="form-label font-size-15 font-weight-600 d-flex align-items-center"><i
                        class="mdi mdi-file-certificate text-theme-dark font-size-24 me-1"></i>Submission Packages </h3>
                <label :for="`sub${storyPackage.id}`" class="mb-0 p-relative labelFieldCol"
                    v-for="storyPackage in getSubmissionPackage()" :key="storyPackage.id">
                    <input type="radio" name="submissionOrFiction" v-model="submission"
                        :value="storyPackage.stripe_package_id" class="inputRadioCstm d-none"
                        :id="`sub${storyPackage.id}`"
                        @click="$emit('submissionPackage', storyPackage.stripe_package_id), regularPackageCheck()" />
                    <span class="radioCircleIcon">
                        <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                        <i class="mdi mdi-checkbox-marked-circle text-theme font-size-20"></i>
                    </span>
                    <div class="row flex-1 align-items-center flex-nowrap">
                        <div class="col">
                            <h4 class="text-name-journl mb-0">{{ storyPackage.title }}<sup
                                    class="text-theme font-size-10"
                                    v-if="storyPackage.title.includes('20 Journals')">(Best Value)</sup></h4>
                        </div>
                        <div class="col-auto">
                            <h3 class="price-style-cstm mb-0">${{ storyPackage.amount }}</h3>
                        </div>
                    </div>
                </label>
                <button @click="uncheckSubmission" style="background-color: white;"
                    class="btn btn-light w-100 btn-sm mt-2" type="button"> Clear Selection </button>
            </div>
        </div>
        <div class="col-lg-4 mb-3  mb-lg-0"
            v-if="isSignUp && !($route.name === 'add-on-service' || $route.name === 'single-story')">
            <div class="package-card-main light-warn-pack">
                <h3 class="form-label font-size-15 font-weight-600 d-flex align-items-center"><i
                        class="mdi mdi-flash text-theme-dark font-size-24 me-1"></i>Flash Fiction Packages</h3>
                <label :for="`sub${fictionPackage.id}`" class="mb-0 p-relative labelFieldCol"
                    v-for="fictionPackage in getFictionPackage()" :key="fictionPackage.id">
                    <input type="radio" name="submissionOrFiction" class="inputRadioCstm d-none" v-model="fiction"
                        :value="fictionPackage.stripe_package_id" :id="`sub${fictionPackage.id}`"
                        @click="$emit('fictionPackage', fictionPackage.stripe_package_id), regularPackageCheck()" />
                    <span class="radioCircleIcon">
                        <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                        <i class="mdi mdi-checkbox-marked-circle text-theme font-size-20"></i>
                    </span>
                    <div class="row flex-1 align-items-center flex-nowrap">
                        <div class="col">
                            <h4 class="text-name-journl mb-0">{{ fictionPackage.title }} <sup
                                    class="text-theme font-size-10"
                                    v-if="fictionPackage.title.includes('20 Journals')">(Best Value)</sup></h4>
                        </div>
                        <div class="col-auto">
                            <h3 class="price-style-cstm mb-0">${{ fictionPackage.amount }}</h3>
                        </div>
                    </div>
                </label>
                <button @click="uncheckFiction" style="background-color: white;" class="btn btn-light w-100 btn-sm mt-2"
                    type="button"> Clear Selection </button>
            </div>
        </div>
        <div class="col-lg-4 mb-3  mb-lg-0"
            v-if="isSignUp && !($route.name === 'add-on-service' || $route.name === 'single-story')">
            <div class="package-card-main light-success-pack">
                <h3 class="form-label font-size-15 font-weight-600 d-flex align-items-center"><i
                        class="mdi mdi-account-supervisor-circle text-theme-dark font-size-24 me-1"></i>Editing &
                    Consulting
                    <sup class="subContent">(discounts may apply)</sup>
                </h3>
                <label :for="`sub${editingPackage.id}`" class="mb-0 p-relative labelFieldCol"
                    v-for="editingPackage in getEditingPackage()" :key="editingPackage.id">
                    <input type="radio" name="editingPack" v-model="editing" :value="editingPackage.stripe_package_id"
                        class="inputRadioCstm d-none" :id="`sub${editingPackage.id}`"
                        @click="$emit('editingPackage', editingPackage.stripe_package_id)"
                        :disabled="editingPackage.title.includes('Read Only') && (submission !== null || fiction !== null)" />
                    <span class="radioCircleIcon">
                        <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                        <i class="mdi mdi-checkbox-marked-circle text-theme font-size-20"></i>
                    </span>
                    <div class="row flex-1 align-items-center flex-nowrap">
                        <div class="col">
                            <h4 class="text-name-journl mb-0">{{ editingPackage.title }}<sup class=" font-size-10"
                                    v-if="editingPackage.title.includes('Read Only')">*</sup></h4>
                        </div>
                        <div class="col-auto">
                            <h3 class="price-style-cstm mb-0">${{ editingPackage.amount }}/<span
                                    class="font-size-12 font-weight-500"><span
                                        v-if="editingPackage.amount == 100 || editingPackage.amount == 10">hour</span><span
                                        v-else>1,000
                                        words</span></span>
                            </h3>
                        </div>
                    </div>
                </label>
                <button @click="uncheckEditing" style="background-color: white;" class="btn btn-light w-100 btn-sm mt-2"
                    type="button"> Clear Selection </button>
            </div>
        </div>
        <div class="col-lg-4 mb-3  mb-lg-0" v-if="!isSignUp">
            <div class="package-card-main light-warn-pack">
                <h3 class="form-label font-size-15 font-weight-600 d-flex align-items-center"><i
                        class="mdi mdi-flash text-theme-dark font-size-24 me-1"></i>Super Packages</h3>
                <label :for="`sub${superPackage.id}`" class="mb-0 p-relative labelFieldCol"
                    v-for="superPackage in getSuperPackage()" :key="superPackage.id">
                    <input type="radio" name="fiction" v-model="superSelect" :value="superPackage.stripe_package_id"
                        class="inputRadioCstm d-none" :id="`sub${superPackage.id}`"
                        @click="$emit('superPackage', superPackage.stripe_package_id)" />
                    <span class="radioCircleIcon">
                        <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                        <i class="mdi mdi-checkbox-marked-circle text-theme font-size-20"></i>
                    </span>
                    <div class="row flex-1 align-items-center flex-nowrap">
                        <div class="col">
                            <h4 class="text-name-journl mb-0">{{ superPackage.title }}</h4>
                        </div>
                        <div class="col-auto">
                            <h3 class="price-style-cstm mb-0">${{ superPackage.amount }}</h3>
                        </div>
                    </div>
                </label>
                <button @click="uncheckSuper" class="btn btn-danger w-100 btn-sm mt-2" type="button"> Clear Selection
                </button>
            </div>
        </div>
        <div class="col-12 mb-3  mb-lg-0"
            v-if="isSignUp && ($route.name === 'add-on-service' || $route.name === 'single-story')">
            <div class="package-card-main light-warn-pack p-3 py-4">
                <h3 class="form-label font-size-15 font-weight-600 d-flex align-items-center"><i
                        class="mdi mdi-flash text-theme-dark font-size-24 me-1"></i>Add-on Packages</h3>
                <label :for="`sub${addOnPk.id}`" class="mb-0 p-relative labelFieldCol"
                    v-for="addOnPk in getAddOnPackage()" :key="addOnPk.id">
                    <input type="radio" name="fiction" v-model="addOn" :value="addOnPk.stripe_package_id"
                        class="inputRadioCstm d-none" :id="`sub${addOnPk.id}`" @click="$emit('addOnPackage', [addOnPk.stripe_package_id, ((JournalAmount != null)
                            && addOnPk.title == '5 Journals') ? (JournalAmount) :
                            addOnPk.amount, addOnPk.title])" />
                    <span class="radioCircleIcon">
                        <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                        <i class="mdi mdi-checkbox-marked-circle text-theme font-size-20"></i>
                    </span>
                    <div class="row flex-1 align-items-center flex-nowrap">
                        <div class="col">
                            <h4 class="text-name-journl mb-0">{{ addOnPk.title }} <sup class="text-theme"
                                    v-if="(addOnPk.title == 'Second Line Edit' || addOnPk.title == 'Second Copy Edit')">(15%
                                    off)</sup></h4>
                        </div>
                        <div class="col-auto">
                            <h3 class="price-style-cstm mb-0">${{ ((JournalAmount != null)
                                && addOnPk.title == '5 Journals') ? (JournalAmount) :
                                addOnPk.amount }} {{ addOnPk.addOnType ? '/' : '' }}
                                <span class="font-size-12 font-weight-500">
                                    <span>{{ addOnPk.addOnType }} {{ addOnPk.addOnType ? 'words' : '' }} </span>
                                    <!-- <span  v-if="addOn.title== 'Second Line Edit'">1,000 words</span> -->
                                </span>
                            </h3>
                            <!-- <h3 class="price-style-cstm mb-0">${{ superPackage.amount }}</h3> -->
                        </div>
                    </div>
                </label>
                <div class="d-flex justify-content-end">
                    <button @click="uncheckAddOn" style="background-color: white;" class="btn btn-light btn-sm mt-2"
                        type="button"> Clear Selection
                    </button>
                </div>

            </div>
        </div>
    </div>

</template>

<script>
import axios from 'axios';
export default ({

    data() {
        return {
            url: process.env.VUE_APP_URL,
            fetchData: false,
            packages: [],
            stories: [],
            extraServices: [],
            submission: null,
            fiction: null,
            editing: null,
            superSelect: null,
            addOn: null,
            story_id: null,
            JournalAmount: null
        }
    },
    props: ['isSignUp'],
    beforeMount() {
        if (!this.fetchData) {
            this.getPackages();
            this.getAllStories();
            // this.getServices();
        }
    },
    activated() {
        if (this.fetchData) {
            this.getPackages();
            this.getAllStories();
            // this.getServices();
        }
        this.submission = null
        this.fiction = null
        this.editing = null
        this.superSelect = null
    },
    // watch: {
    //     $route() {
    //         this.getAddOnPackage()
    //     }
    // },
    methods: {
        regularPackageCheck() {
            if (this.editing !== null) {
                let packageCon = this.packages.find(item => item.stripe_package_id == this.editing);
                if (packageCon.title == 'Read Only') {
                    this.editing = null;
                    this.$emit('editingPackage', null)
                }
            }
        },
        serviceDataII(data) {
            this.story_id = data;
            this.getAddOnPackage()
        },
        uncheckSubmission() {
            this.submission = null;
            this.fiction = null;
            this.$emit('submissionPackage', null)
        },
        uncheckFiction() {
            this.fiction = null;
            this.submission = null;
            this.$emit('fictionPackage', null)
        },
        uncheckEditing() {
            this.editing = null;
            this.$emit('editingPackage', null)
        },
        uncheckSuper() {
            this.superSelect = null;
            this.$emit('superPackage', null)
        },
        uncheckAddOn() {
            this.addOn = null;
            this.$emit('addOnPackage', null)
        },
        getPackages() {
            axios.get(this.url + 'api/getPackages').then(res => {
                this.packages = res.data
                this.getServices();
            })
        },
        getServices() {
            axios.get(this.url + 'api/getServices').then(res => {
                this.extraServices = res.data
                this.fetchData = true;
            })
        },
        getAllStories() {
            axios.get(this.url + 'api/getAllStories').then(res => {
                this.stories = res.data;
                // this.storyTitleOptions = this.tableData.map(({ storyTitle }) => storyTitle);
            })
        }
    },
    computed: {
        getSubmissionPackage() {
            return () => this.packages.filter(storyPackage => storyPackage.type == 'submission')
        },
        getFictionPackage() {
            return () => this.packages.filter(storyPackage => storyPackage.type == 'flash')
        },
        getEditingPackage() {
            let admin = localStorage.getItem("userType1") != (null || '') ? atob(decodeURIComponent(JSON.parse(localStorage.getItem("userType1")))) : ''
            if (admin == 'admin') {
                return () => this.packages.filter(storyPackage => storyPackage.type == 'editing')
            } else {
                return () => this.packages.filter(storyPackage => storyPackage.type == 'editing' && storyPackage.title != 'Miscellaneous')
            }
        },
        getSuperPackage() {
            return () => this.packages.filter(storyPackage => storyPackage.type == 'super')
        },
        getAddOnPackage() {
            let getFilteredPackages = () => {
                if (this.story_id) {
                    let stories = this.stories.filter(str => str.id == this.story_id)[0];
                    if (!stories) return [];

                    let story = [];
                    let storyII = [];
                    let storyIII = [];
                    let storyIV = [];
                    let storyV = [];
                    story = this.stories.filter(str => str.id == this.story_id).map(item => item.submissionPackageId).filter(a => a);
                    storyII = this.stories.filter(str => str.id == this.story_id).map(item => item.fictionPackageId).filter(a => a);
                    storyIII = this.stories.filter(str => str.id == this.story_id).map(item => item.editingPackageId).filter(a => a);
                    storyIV = this.stories.filter(str => str.id == this.story_id).map(item => item.addOnPackage?.split(',')).filter(a => a);
                    storyV = this.extraServices.filter(str => str.story_id.id == this.story_id && str.status != 'Pending').map(item => item.addOnPackageId).filter(a => a);
                    let title = [];
                    let titleII = [];
                    let titleIII = [];
                    let titleIV = [];
                    let titleV = [];
                    if (story.length > 0) {
                        title = this.packages.filter(pack => pack.id == story[0]).map(item => item.title);
                    }
                    if (storyII.length > 0) {
                        titleII = this.packages.filter(pack => pack.id == storyII[0]).map(item => item.title);
                    }
                    if (storyIII.length > 0) {
                        titleIII = this.packages.filter(pack => pack.id == storyIII[0]).map(item => item.title);
                    }
                    if (storyIV.length > 0) {
                        storyIV.forEach(array => {
                            if (array.length > 0) {
                                let packageTitles = this.packages.filter(pack => array.includes(pack.stripe_package_id)).map(item => item.title);
                                titleIV.push(...packageTitles);
                            }
                        });
                    }
                    if (storyV.length > 0) {
                        storyV.forEach(array => {
                            if (array.length > 0) {
                                let packageTitles = this.packages.filter(pack => array.includes(pack.stripe_package_id)).map(item => item.title);
                                titleV.push(...packageTitles);
                            }
                        });
                    }

                    this.JournalAmount = null;
                    if (title.length > 0) {
                        if (title[0] == '10 Journals') {
                            this.JournalAmount = 70;
                        } else if (title[0] == '15 Journals') {
                            this.JournalAmount = 60;
                        } else if (title[0] == '20 Journals') {
                            this.JournalAmount = 50;
                        }
                    }

                    if (titleII.length > 0) {
                        if (titleII[0].includes('10 Journals (3 Stories)')) {
                            this.JournalAmount = 100;
                        } else if (titleII[0].includes('15 Journals (3 Stories)')) {
                            this.JournalAmount = 85;
                        } else if (titleII[0].includes('20 Journals (3 Stories)')) {
                            this.JournalAmount = 60;
                        }
                    }

                   let  includeJournalsList = title.includes('Journals List Only');
                   let  includetitleIVLine = titleIV.includes('Line Editing');
                   let  includetitleIVCopy = titleIV.includes('Copy Editing');
                   let  includetitleVLine = titleV.includes('Line Editing')
                   let  includetitleVCopy = titleV.includes('Copy Editing')
                    return this.packages.filter(storyPackage => {
                        if (storyPackage.type !== 'addOn') return false;
                        if (includeJournalsList && storyPackage.title == '5 Journals') return false;
                        if (includeJournalsList && storyPackage.title == '15 Journals') return true;
                        if (!includeJournalsList && storyPackage.title == '15 Journals') return false;

                        if (includetitleIVLine && (storyPackage.title == 'Line Editing' || storyPackage.title == 'Second Copy Edit')) return false;
                        if (includetitleIVCopy && (storyPackage.title == 'Copy Editing' || storyPackage.title == 'Second Line Edit')) return false;
                        if (includetitleIVLine && storyPackage.title == 'Second Line Edit') return true;
                        if (includetitleIVCopy && storyPackage.title == 'Second Copy Edit') return true;


                        if (includetitleVLine && (storyPackage.title == 'Line Editing' || storyPackage.title == 'Second Copy Edit')) return false;
                        if (includetitleVCopy && (storyPackage.title == 'Copy Editing' || storyPackage.title == 'Second Line Edit')) return false;
                        if (includetitleVLine && storyPackage.title == 'Second Line Edit') return true;
                        if (includetitleVCopy && storyPackage.title == 'Second Copy Edit') return true;

                        if (titleIII.length > 0) {
                            if (titleIII[0].includes('Line Editing') && (storyPackage.title == 'Line Editing' || storyPackage.title == 'Second Copy Edit')) return false;
                            if (titleIII[0].includes('Copy Editing') && (storyPackage.title == 'Copy Editing' || storyPackage.title == 'Second Line Edit')) return false;
                            if (titleIII[0].includes('Line Editing') && storyPackage.title == 'Second Line Edit') return true;
                            if (titleIII[0].includes('Copy Editing') && storyPackage.title == 'Second Copy Edit') return true;
                            // if (titleIII[0].includes('Line Editing') && storyPackage.title == 'Line Editing') return false;
                            // if (titleIII[0].includes('Copy Editing') && storyPackage.title == 'Copy Editing') return false;
                            // if (titleIII[0].includes('Copy Editing') && storyPackage.title == 'Second Line Edit') return true;
                            // if (titleIII[0].includes('Line Editing') && storyPackage.title == 'Second Copy Edit') return true;
                        }
                        // else {
                        //     if (storyPackage.title == 'Second Copy Edit' || storyPackage.title == 'Second Line Edit') return false;
                        // }

                        const noEditingPackagesSelected = !(
                            title.includes('Line Editing') ||
                            title.includes('Copy Editing') ||
                            titleIV.includes('Line Editing') ||
                            titleIV.includes('Copy Editing') ||
                            titleV.includes('Line Editing') ||
                            titleV.includes('Copy Editing') ||
                            (titleIII.length > 0 && (
                                titleIII[0].includes('Line Editing') ||
                                titleIII[0].includes('Copy Editing')
                            ))
                        );

                        // If no editing packages are selected, include "Second Line Edit" and "Second Copy Edit"
                        if (noEditingPackagesSelected && (storyPackage.title === 'Second Line Edit' || storyPackage.title === 'Second Copy Edit')) {
                            return false;
                        }

                        return true;
                    });
                } else {
                    let story = this.stories.map(item => item.submissionPackageId);
                    let title = this.packages.filter(pack => pack.id == story).map(item => item.title);
                    let includeJournalsListOnly = title.includes('Journals List Only');

                    return this.packages.filter(storyPackage => {
                        if (storyPackage.type !== 'addOn') return false;
                        if (includeJournalsListOnly && storyPackage.title == '5 Journals') return false;
                        if (includeJournalsListOnly && storyPackage.title == '15 Journals') return true;
                        if (!includeJournalsListOnly && storyPackage.title == '15 Journals') return false;
                        if (storyPackage.title == 'Second Line Edit' || storyPackage.title == 'Second Copy Edit') return false;
                        return true;
                    });
                    // let story = this.stories.map(item => item.submissionPackageId);
                    // let title = this.packages.filter(pack => pack.id == story).map(item => item.title);
                    // return this.packages.filter(storyPackage => storyPackage.type == 'addOn' && (title.includes('Journals List Only') ? storyPackage.title != '5 Journals' : storyPackage.type == 'addOn'));
                }
            };

            return getFilteredPackages;
        },

    }
})
</script>